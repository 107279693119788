import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';

export interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => (
  <div className="screen__container">
    <Layout>
      <SEO title="Página no encontrada" />
      <Banner
        title="Parece que no hemos encontrado lo que estabas buscando"
        subtitle="Si quieres conocer más acerca de nosotros dirígete a nuestro inicio"
        image="/images/Proyectat-09.png"
        buttonText="Ir al inicio"
        buttonLink="/"
        mobileBackgroundColor="#E6E6E6"
        dark={true}
        hasButton={true}
      ></Banner>
    </Layout>
  </div>
);

export default NotFound;
